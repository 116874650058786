
import { Component, Prop, Vue } from 'vue-property-decorator'

type ColorType = 'white' | 'blue' | 'green' | 'red' | 'purple' | 'gray'

@Component({
  name: 'Badge',
})
export default class Badge extends Vue {
  @Prop({ default: 'white' })
  color!: ColorType

  @Prop({ default: 'green' })
  floatColor!: ColorType

  @Prop({ default: '' })
  tooltip!: string
}
