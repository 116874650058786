
import { Component, Ref } from 'vue-property-decorator'
import { toLocalCurrency } from '@/utils/currencyFormatter'
import InvoicesService from '@/core/services/InvoicesService'
import { InvoiceModel } from '@/core/models/InvoicesModel'

import Modal from '@/components/Modal.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import Badge from '@/components/Badge.vue'
import Spinner from '@/components/Spinner.vue'
import VueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'

import { BreakpointsMixin } from '@/mixins/breakpoints.mixin'

@Component({
  name: 'InvoiceList',
  components: {
    Modal,
    BaseIcon,
    BaseButton,
    Badge,
    Spinner,
    VueCustomScrollbar,
  },
})
export default class InvoiceList extends BreakpointsMixin {
  @Ref() readonly download!: HTMLAnchorElement

  toLocalCurrency = toLocalCurrency
  loading = false
  invoiceList = [] as InvoiceModel[]
  errorMessage = ''
  showErrorModal = false
  expanded: number[] = []

  get invoiceFileName(): string | boolean {
    if (this.isTablet) {
      return 'deliroom-invoice'
    }
    return false
  }

  created(): void {
    this.prepare()
  }

  async prepare(): Promise<void> {
    this.loading = true
    const query = Object.assign({}, this.$route.query)
    const { orderId } = query
    if (orderId && typeof orderId === 'string') {
      await this.updatePayment(orderId)
      delete query.orderId
      this.$router.replace({ query })
    }
    await this.getInvoices()
    this.loading = false
    if (query.download) {
      await this.downloadInvoice(+query.download)
    }
  }

  async updatePayment(uid: string): Promise<void> {
    try {
      await InvoicesService.updatePayment(uid)
    } catch (error) {
      this.errorHandler(error)
    }
  }

  async getInvoices(): Promise<void> {
    try {
      const invoices = await InvoicesService.getInvoices()
      if (invoices instanceof Array) {
        if (invoices.length) {
          invoices.sort((a, b) => b.id - a.id)
          this.invoiceList = invoices
        }
      }
    } catch (error) {
      this.errorHandler(error)
    }
  }

  async downloadInvoice(id: number): Promise<void> {
    try {
      const blob = await InvoicesService.downloadInvoice(id)
      const url = window.URL.createObjectURL(blob)
      this.download.href = url
      this.download.click()
    } catch (error) {
      this.errorHandler(error)
    }
  }

  async pay(id: number): Promise<void> {
    try {
      const response = await InvoicesService.payInvoice(id)
      if (response?.payment_link) {
        this.redirect(response.payment_link)
      } else {
        this.errorMessage = 'Ссылка не найдена'
        this.showErrorModal = true
      }
    } catch (error) {
      this.errorHandler(error)
    }
  }

  redirect(url: string): void {
    if (typeof url === 'string' && url.startsWith('http')) {
      window.location.href = url
    } else {
      this.$router.push(url)
    }
  }

  errorHandler(error: unknown): void {
    this.errorMessage = ''
    if (InvoicesService.isServiceError(error)) {
      const errors = error.response?.data?.errors || []
      this.errorMessage = errors[0] || error.message
    }
    this.errorMessage = this.errorMessage || 'Неизвестная ошибка'
    this.showErrorModal = true
  }

  toggle(id: number): void {
    if (this.expanded.includes(id)) {
      this.expanded = this.expanded.filter((item) => item !== id)
    } else {
      this.expanded.push(id)
    }
  }
}
